<template>
  <layout-landing>
    <div class="landing reset-password container">
      <header class="landing__header">
        <div class="landing__logo-container">
          <img
            alt="Logo Sayl Connect"
            class="landing__logo"
            :src="logo"
          />
        </div>
      </header>

      <div class="landing__body">
        <!-- Branding -->
        <div class="landing__branding">
          <h1 class="title">{{ $t('conn3ct-wallet.reset_password_form_title') }}</h1>
          <div class="description">
            <p v-if="!done" v-html="$t('conn3ct-wallet.reset_password_form_description')"></p>
            <p v-else v-html="$t('conn3ct-wallet.reset_password_confirmation_descripion')"></p>
          </div>
        </div>

        <div v-if="!done">
          <!-- Form -->
          <div class="landing__form">
            <div
              class="landing__error"
              v-if="!$basil.isNil(error)"
            >{{ error }}</div>

            <forms-input
              v-model="email"
              class="landing__input"
              icon-post="user"
              :placeholder="$t('conn3ct-wallet.email_or_phone_placeholder')"
              :hint="$t('conn3ct-wallet.reset_password_hint')"
            />

            <actions-button
              @click="onConfirm"
              :appearance="$pepper.Appearance.PRIMARY"
              class="landing__action"
              :disabled="loading || !isFilled"
              :loading="loading"
            >{{ $t('conn3ct-wallet.reset_password_action') }}</actions-button>
          </div>

          <div class="landing__signature">
            {{ $t('conn3ct-wallet.register_have_account_label') }}
            <a
              @click="$router.push({ name: 'sayl-connect_landing-login' })"
              class="landing__signature-action"
              :size="$pepper.Size.S"
            >{{ $t('conn3ct-wallet.register_sign_in_action') }}</a>
          </div>
        </div>

        <div
          v-else
          class="landing__done"
        >
          <span>{{ $t('conn3ct-wallet.reset_password_email_or_sms_sent_label') }}</span>

          <actions-button
            @click="$router.push({ name: 'sayl-connect_landing-login' })"
            class="landing__action"
            :disabled="loading || !isFilled"
            icon-post="redo"
            :loading="$user.loading"
          >{{ $t('conn3ct-wallet.go_on_login') }}</actions-button>
        </div>
      </div>
    </div>
  </layout-landing>
</template>

<script>
import LayoutLanding from '@/layouts/landing.vue'
import Header from '@/components/navigation/header.vue'
import { mapState } from 'vuex'

export default {
  name: 'SaylConn3ctLostPassword',

  components: {
    LayoutLanding,
    Header
  },

  data() {
    return {
      done: false,
      email: this.$basil.get(this.$route, 'params.email', ''),
      error: null,
      loading: false,
    }
  },

  computed: {
    ...mapState({
      bootstrap: state => state.bootstrap,
    }),

    isFilled() {
      return [
        this.$basil.isNil(this.email),
        this.$basil.isEmpty(this.email),
      ].filter(f => f === true).length === 0
    },

    merchantLogo() {
      return this.bootstrap?.data?.brand?.logo
    },

    logo() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.url)
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    }
  },

  methods: {
    onRefresh() {
      this.done = false
    },

    onConfirm() {
      this.loading = true
      this.error = null

      if(this.email.indexOf('+') !== 0 && !this.email.includes('@')) {
        this.loading = false
        this.error = this.$t('conn3ct-wallet.lost_password_incorrect_identifier')
        return
      }

      this.$user.resetPassword({ email: this.email })
        .then(() => this.done = true)
        .catch((e) => {
          $console.error(e)
          if(this.$basil.get(e, 'status', 500) === 404){
            this.error = this.$t('conn3ct-wallet.lost_password_identifier_not_found')
          }
        })
        .finally(() => this.loading = false)
    },

    onPressEnter(e) {
      if(e.keyCode === 13 && this.isFilled && !this.loading) {
        this.onConfirm()
      }
    }
  },

  mounted() {
    window.addEventListener('keypress', this.onPressEnter)
  },

  beforeDestroy() {
    window.removeEventListener('keypress', this.onPressEnter)
  }
}
</script>
